.alt-font {
  font-family: 'Ubuntu Mono', monospace;
}

.welcome::before {
  border-bottom: 0;
  border-top: 1px solid #3b82f6;
  content: '';
  display: block;
  flex-grow: 1;
  margin-right: 8px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
}

@media (min-width: 768px) {
  .about-section {
    background-image: url('assets/about-bg.svg');
    background-position: 70vw 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
